import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import { paginationChange } from '../util/helper'
import SliceRenderer from '../components/ContentBlocks/SliceRenderer'
import ImageBlockWithText from '../components/ContentBlocks/ImageBlockWithText'
import BodyContentBlock from '../components/ContentBlocks/BodyContentBlock'
import GridBlock from '../components/ContentBlocks/GridBlock'
import SubPageHero from '../components/Heroes/SubPageHero'
import { BottomLineGoogleAdContainer } from '../components/styles/GoogleAdsCommon.styles'
import AdSlot from '../components/AdSlot'
import AdHead from '../components/AdHead'

export const Head = ({ pageContext }) => {
  return (
    <AdHead adsData={pageContext.adsData} targets={pageContext.pageTargets} />
  )
}

const ResourceLibraryItemDownloadPageFooter = ({ data }) => {
  const footerDoc = data.prismicResourceLibraryDownloadPageFooter.data
  if (!footerDoc) {
    return null
  }

  return (
    <>
      {footerDoc.body &&
        footerDoc.body.map((block, index) => (
          <SliceRenderer block={block} key={index} />
        ))}
    </>
  )
}

const ResourceLibraryItemDownloadPage = ({ data }) => {
  const doc = data.prismicResourceLibraryItem.data
  if (!doc) {
    return null
  }

  const settingsNode = data.prismicGeneralSiteSettings.data
  const bottomLineAdSlot =
    settingsNode.subpages_google_ad_tag?.document?.data?.ad_slot_name
  const heroText = doc.page_title ? doc.page_title : ''
  const hasImage = doc.image && doc.image.url
  const hasSummary = doc.summary && doc.summary.richText
  const hasDownloadLink = doc.download_link && doc.download_link.url
  const ctaHeaderText =
    doc.download_page_cta_header && doc.download_page_cta_header.text !== ''
      ? doc.download_page_cta_header.text
      : 'Download Your Guide'
  const ctaLinkText =
    doc.download_page_cta_link_text &&
    doc.download_page_cta_link_text.text !== ''
      ? doc.download_page_cta_link_text.text
      : 'Download the guide'

  const imageBlockData = {
    layout_option: 'Image Left / Text Right',
    text_content: doc.summary || null,
    header_text: { text: ctaHeaderText },
    cta_link: doc.download_link
      ? {
          url: doc.download_link.url,
          uid: doc.download_link.uid,
          type: doc.download_link.type,
          link_type: doc.download_link.link_type,
          target: '_blank',
        }
      : null,
    cta_text: {
      text: ctaLinkText,
    },
    image: {
      url: doc.image.thumbnails.download_page.url,
      thumbnails: {
        mobile: {
          url: doc.image.thumbnails.download_page_mobile.url,
        },
      },
      alt: doc.image.alt,
    },
  }

  const twitterImage = doc.twitter_image?.url ? doc.twitter_image : null
  const facebookImage = doc.facebook_image?.url ? doc.facebook_image : null
  const seoTitle = doc.meta_title?.text
    ? doc.meta_title.text
    : doc.page_title?.text
    ? doc.page_title.text
    : null
  const seo_embed_code = doc.seo_embed_code?.text ?? null
  const meta_description = doc.meta_description
  const meta_keywords = doc.meta_keywords
  return (
    <>
      <SEO
        seo={{
          description: meta_description,
          keywords: meta_keywords,
          title: seoTitle,
          twitter_image: twitterImage,
          facebook_image: facebookImage,
          seo_embed_code: seo_embed_code,
        }}
      />
      <Layout>
        {/* <pre>{JSON.stringify(doc, null, 2)}</pre> */}
        <SubPageHero heroText={heroText} />
        <ImageBlockWithText
          data={imageBlockData}
          // bottomPaddingAdjustment={'2.5%'}
        />
        <ResourceLibraryItemDownloadPageFooter data={data} />
        {bottomLineAdSlot && (
          <BottomLineGoogleAdContainer>
            <AdSlot divId={`div-${bottomLineAdSlot}`} />
          </BottomLineGoogleAdContainer>
        )}
      </Layout>
    </>
  )
}

// test with db2887a1-6ca8-5562-83cc-b5e7e88bc3b7
export const query = graphql`
  query ($id: String) {
    prismicGeneralSiteSettings {
      data {
        subpages_google_ad_tag {
          document {
            ... on PrismicGoogleAdTag {
              id
              data {
                ad_slot_name
              }
            }
          }
        }
      }
    }
    prismicResourceLibraryItem(id: { eq: $id }) {
      uid
      id
      data {
        meta_title {
          text
        }
        meta_description
        meta_keywords
        seo_embed_code {
          text
        }
        facebook_image {
          url
        }
        twitter_image {
          url
        }
        page_title {
          richText
          text
        }
        summary {
          richText
          text
        }
        image {
          alt
          thumbnails {
            download_page {
              url
            }
            download_page_mobile {
              url
            }
          }
          url
        }
        download_page_cta_header {
          text
        }
        download_page_cta_link_text {
          text
        }
        download_link {
          target
          link_type
          url
          uid
          type
        }
      }
    }
    prismicResourceLibraryDownloadPageFooter {
      data {
        body {
          ... on PrismicResourceLibraryDownloadPageFooterDataBodyAccordionBlock {
            id
            slice_type
            primary {
              section_header {
                text
              }
              bottom_padding_adjustment
            }
            items {
              content {
                richText
              }
              block_header {
                text
              }
            }
          }
          ... on PrismicResourceLibraryDownloadPageFooterDataBodyAdvancedImageBlock {
            id
            slice_type
            primary {
              layout_option
              text_content {
                richText
              }
              cta_text {
                text
              }
              cta_link {
                uid
                link_type
                id
                type
                url
              }
              header_text {
                text
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicResourceLibraryDownloadPageFooterDataBodyAuthorBlock {
            id
            slice_type
            primary {
              author {
                document {
                  ... on PrismicGuestAuthor {
                    id
                    type
                    data {
                      name {
                        text
                      }
                      title {
                        text
                      }
                      author_image {
                        alt
                        url
                      }
                    }
                  }
                  ... on PrismicNpfAuthor {
                    id
                    type
                    uid
                    data {
                      name {
                        text
                      }
                      title {
                        text
                      }
                      bio {
                        richText
                      }
                      author_image {
                        alt
                        url
                      }
                    }
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicResourceLibraryDownloadPageFooterDataBodyBasicImageBlock {
            id
            slice_type
            primary {
              caption {
                text
              }
              image_link {
                url
                uid
                type
                link_type
                id
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicResourceLibraryDownloadPageFooterDataBodyBodyContent {
            id
            slice_type
            primary {
              body_copy {
                richText
              }
            }
          }
          ... on PrismicResourceLibraryDownloadPageFooterDataBodyCalendarOfEventsBlock {
            id
            slice_type
            primary {
              bottom_padding_adjustment
            }
          }
          ... on PrismicResourceLibraryDownloadPageFooterDataBodyCtaBlock {
            id
            slice_type
            primary {
              cta_link_text
              layout_style
              cta_link {
                id
                url
                uid
                type
                link_type
              }
              body_text {
                richText
              }
              header {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicResourceLibraryDownloadPageFooterDataBodyEventBlock {
            id
            slice_type
            primary {
              bottom_padding_adjustment
            }
            items {
              event {
                id
                document {
                  ... on PrismicEvent {
                    data {
                      end_date
                      event_date
                      event_city
                      event_state
                      end_time
                      start_time
                      event_venue
                      event_address_line_1
                      event_address_line_2
                      event_image {
                        alt
                        url
                        thumbnails {
                          mobile {
                            url
                          }
                        }
                      }
                      event_title {
                        text
                      }
                      event_description {
                        text
                      }
                      event_link {
                        id
                        link_type
                        uid
                        url
                        type
                      }
                      event_link_text
                    }
                  }
                }
              }
            }
          }
          ... on PrismicResourceLibraryDownloadPageFooterDataBodyGoogleAd {
            id
            slice_type
            primary {
              google_ad_tag {
                document {
                  ... on PrismicGoogleAdTag {
                    id
                    data {
                      ad_slot_name
                    }
                  }
                }
              }
            }
          }
          ... on PrismicResourceLibraryDownloadPageFooterDataBodyGridBlock {
            id
            slice_type
            primary {
              layout_type
            }
            items {
              cta_link_text
              cta_link {
                url
                uid
                type
                link_type
                id
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              summary {
                richText
              }
              title {
                text
              }
            }
          }
          ... on PrismicResourceLibraryDownloadPageFooterDataBodyIconTextBlock {
            id
            slice_type
            primary {
              header {
                richText
              }
              body_text {
                richText
              }
              bottom_padding_adjustment
            }
            items {
              cta_text
              item_title {
                richText
              }
              item_icon {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              item_body_text {
                richText
              }
              cta_link {
                id
                url
                uid
                type
                link_type
              }
            }
          }
          ... on PrismicResourceLibraryDownloadPageFooterDataBodySponsorsBlock {
            id
            slice_type
            items {
              sponsor_logo {
                alt
                url
              }
              sponsor_link {
                url
                uid
                link_type
                id
              }
            }
            primary {
              intro_text {
                richText
              }
              header {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicResourceLibraryDownloadPageFooterDataBodyStatisticsBlock {
            id
            slice_type
            primary {
              citation
              statistic_prompt
              header {
                text
              }
              subhead {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicResourceLibraryDownloadPageFooterDataBodyVideoBlock {
            id
            slice_type
            primary {
              caption {
                text
              }
              schema_publish_date
              schema_video_description
              video_duration_hours
              video_duration_minutes
              video_duration_seconds
              transcription_link {
                url
                uid
                type
                link_type
              }
              video_title {
                text
              }
              video_thumbnail {
                url
              }
              video_link {
                embed_url
              }
              bitmovin_stream_id
              video_width
              bottom_padding_adjustment
            }
          }
          ... on PrismicResourceLibraryDownloadPageFooterDataBodyCustomForm {
            id
            slice_type
            primary {
              form_title
              form_body {
                richText
              }
              form_embed_code {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicResourceLibraryDownloadPageFooterDataBodyTable {
            id
            slice_type
            items {
              column_1_content {
                richText
              }
              column_2_content {
                richText
              }
              column_3_content {
                richText
              }
            }
            primary {
              table_title {
                richText
              }
            }
          }
        }
      }
    }
  }
`

export default ResourceLibraryItemDownloadPage
